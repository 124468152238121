import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { api } from "services";
import { useTranslation } from "react-i18next";
import ConfirmDelete from "../popups/confirmDelete";
import Spinner from "components/shared/Spinner";

const Index = () => {
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [deleteEventId, setDeleteEventId] = useState();
  const [userRole, setUserRole] = useState(null);
  const [showAction, setShowisAction] = useState(true);
  const [action, setAction] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("myevents");

  const navigate = useNavigate();

  //method to get the events which are live(isLive=1) or draft(isLive=0)
  const futureEvents = (events) => {
    const eventData = events.filter((item) => {
      return item?.get("isLive") !== 2;
    });
    return eventData;
  };

  //method to get the events which are completed(isLive=2)
  const pastEvents = (events) => {
    const eventData = events.filter((item) => {
      return item?.get("isLive") == 2;
    });
    return eventData;
  };

  //sorting events based on date in desending order based on creation event time
  const sortingData = (events) => {
    const publshedData = events?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    return publshedData;
  };

  //method to check the current loggedIn user role
  const checkRole = async () => {
    try {
      const role = await api.getCurrentUserRoles();
      if (role === "admin") {
        const events = await api.getEvents();
        let sortedData = sortingData(futureEvents(events));
        setAllEvents(events);
        setEvents(sortedData);
        setUserRole(role);
      } else if (role === "organizer") {
        const events = await api.getEventByUserId();
        let sortedData = sortingData(futureEvents(events));
        setAllEvents(events);
        setEvents(sortedData);
        setUserRole(role);
      } else if (role === "user") {
        setUserRole(role);
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  //on page load fetch the current user role
  useEffect(() => {
    checkRole();
  }, [deleteEventId, action]);

  //handle go to previous page
  const goBack = () => {
    navigate(-1);
  };

  //if eventId is present then event form act as edit page else add page
  const editEvent = (id) => {
    navigate(`/eventEdit/${id}`);
  };

  //method to delete the event by eventId
  const deleteEvent = async () => {
    const res = await api.deleteEventbyId(deleteEventId);
    if (res) {
      setDeleteEventId(null);
      navigate("/events");
    }
  };

  //get the future events
  const showUpcomingEvents = async () => {
    if (userRole === "organizer") {
      let sortedData = sortingData(futureEvents(allEvents));
      setEvents(sortedData);
      setShowisAction(true);
    }
    if (userRole === "admin") {
      let sortedData = sortingData(futureEvents(allEvents));
      setEvents(sortedData);
      setShowisAction(true);
    }
  };

  //get the past event
  const showPastEvents = async () => {
    if (userRole === "organizer") {
      setEvents(pastEvents(allEvents));
      setShowisAction(false);
    }
    if (userRole === "admin") {
      setEvents(pastEvents(allEvents));
      setShowisAction(false);
    }
  };

  const handleGetDate = (eventdate) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(eventdate);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    let convertedMinuts = minutes < 10 ? "0" + minutes : minutes;

    return `${day} ${month} ${year}, ${hours}:${convertedMinuts}`;
  };

  if (loading) return <Spinner />;

  if (userRole === "user") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="container">
      <div className="row col-md-10 offset-md-1">
        <div className="col-1">
          <i
            className="bi bi-arrow-left-circle h1"
            onClick={goBack}
            role="button"
          ></i>
        </div>
        <div className="col-9">
          <h1 className="text-left mx-3 mx-md-0">
            <b>{t("title")}</b>
          </h1>
        </div>
        <div className="col-2 px-0">
          {userRole !== "user" && (
            <Link
              to="/events/addEvent"
              className="text-reset text-decoration-none"
            >
              <button className="btn btn-secondary btn-block py-md-2 fw-bold d-none d-md-block">
                <i className="bi bi-plus-circle h5 mx-2" role="button"></i>
                {t("newEvent")}
              </button>
              <button className="btn btn-secondary d-block d-md-none">
                <i className="bi bi-plus-circle h5" role="button"></i>
              </button>
            </Link>
          )}
        </div>
        <hr className="my-3" />
        <div className="mb-3">
          <ul className="nav nav-pills d-flex" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active fw-bold"
                id="nav-upcoming"
                data-bs-toggle="tab"
                data-bs-target="#upcoming"
                type="button"
                role="tab"
                aria-controls="nav-upcoming"
                aria-selected="true"
                onClick={showUpcomingEvents}
              >
                {t("upcommingShows.title")}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link fw-bold"
                id="nav-failed"
                data-bs-toggle="tab"
                data-bs-target="#failed"
                type="button"
                role="tab"
                onClick={showPastEvents}
              >
                {t("completedShows.title")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1 mb-5">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {showAction ? (
                    <th scope="col">{t("upcommingShows.title")}</th>
                  ) : (
                    <th>{t("completedShows.title")}</th>
                  )}
                  {userRole === "admin" && (
                    <th scope="col">{t("upcommingShows.organizer")}</th>
                  )}
                  <th scope="col">{t("upcommingShows.ticketSoldOut")}</th>
                  {/* <th>Event Created</th> */}
                  <th scope="col">{t("upcommingShows.datetime")}</th>
                  <th scope="col">{t("upcommingShows.status")}</th>
                  {showAction && (
                    <>
                      <th scope="col">{t("upcommingShows.actions")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {events &&
                  events?.map((event, j) => {
                    return (
                      <tr key={j}>
                        <th scope="row">{++j}</th>
                        <td>{event?.get("title")}</td>
                        {userRole === "admin" && (
                          <td>{event?.get("organizerId")?.get("username")}</td>
                        )}
                        <td>
                          {event?.get("bookings_count")?.sold_tickets}/
                          {event?.get("bookings_count")?.totalTickets}
                        </td>
                        <td>{handleGetDate(event?.get("date"))}</td>
                        {/* <td>{event?.get("isLive") ? "Published" : "Draft"}</td> */}
                        {event?.get("isLive") == 0 && <td>Draft</td>}
                        {event?.get("isLive") == 1 && <td>Live</td>}
                        {event?.get("isLive") == 2 && <td>Completed</td>}
                        <td align="center">
                          {event?.get("isLive") !== 2 && (
                            <div className="d-flex">
                              <i
                                className="bi bi-pencil h4 mx-2"
                                onClick={() => editEvent(event?.id)}
                                role="button"
                              ></i>
                              <i
                                className="bi bi-trash h4 mx-2"
                                onClick={() => {
                                  setDeleteEventId(event?.id);
                                  setAction({
                                    actionName: deleteEvent,
                                    actionBody:
                                      "Are you sure You want to Delete Event",
                                    actionTitle: "Delete Event",
                                  });
                                }}
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#confirmDeleteModal"
                              ></i>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmDelete handleAction={deleteEvent} action={action} />
    </div>
  );
};

export default Index;