import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate();

  return (
    <div className='text-center my-5'>
      <h1 className='fs-1 fw-bold'>404</h1>
      <p  className='fs-1'>Page Not Found</p>
      <button onClick={()=>navigate("/")} className='btn btn-secondary'>Go Home</button>
    </div>
  )
}

export default NotFound
