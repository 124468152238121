
import {loadStripe} from "@stripe/stripe-js";
import { 
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { stripe } from "services";
import { useLocation } from "react-router-dom";

const STRIPE_KEY = process.env.NODE_ENV == 'development'
? process.env.REACT_APP_STRIPE_TEST_API_KEY
: process.env.REACT_APP_STRIPE_API_KEY;

const stripePromise = loadStripe(STRIPE_KEY);

const Checkout = (props) => {
    const [clientSecret, setClientSecret] = useState('');
    const location = useLocation();
    const { amount, email, bookingId } = location?.state;
    
    useEffect(() => {
        stripe.createCheckout(amount, email, bookingId)
            .then((data) => setClientSecret(data?.clientSecret));
    }, []);

    return (
        <div class="container py-5">
                {clientSecret && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{clientSecret}}>
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                        
                )}
        </div>
    )
}

export default Checkout;