export const ValidateBankFields = (name, value) => {
  switch (name) {
    case "accountName":
      return validateText(value);
    case "accountType":
      return validateAccountType(value);
    case "code":
      return validateCode(value);
    case "location":
      return validateText(value);
    case "accountNumber":
    case "confirmAccountNumber":
      return validateNumber(value);
    default:
      return "Unknown field";
  }
};

export const validateText = (value) => {
  if (!value?.trim()) {
    return "This is a required field";
  }
  return "";
};

export const validateNumber = (value) => {
  // Regular expression to match only digits
  const regex = /^\d+$/;

  // Check if the value is provided
  if (!value) {
    return "This is a required field";
  }

  // Check if the value contains only digits
  if (!regex.test(value)) {
    return "Invalid number format. Only digits are allowed.";
  }

  // Check the length of the value
  if (value.length < 8 || value.length > 17) {
    return "Number must be between 8 and 17 digits long";
  }

  return "";
};

export const validateAccountType = (value) => {
  const validTypes = ["Current", "Saving", "Overdraft"]; // Add more types if needed
  if (!value?.trim()) {
    return "This is a required field";
  }
  if (!validTypes?.includes(value)) {
    return "Invalid account type";
  }
  return "";
};

export const validateCode = (value) => {
  // Check if the input is empty
  if (value?.length === 0) {
    return "This is a required field";
  }

  // SWIFT codes are either 8 or 11 characters, alphanumeric
  if (!/^[A-Za-z0-9]{8}(?:[A-Za-z0-9]{3})?$/.test(value)) {
    return "Invalid SWIFT code format it should be 8 or 11 Characters";
  }

  return "";
};

export const BankForm = (formData) => {
  const newErrors = {
    accountName: validateText(formData?.accountName),
    accountNumber: validateNumber(formData?.accountNumber),
    confirmAccountNumber: validateNumber(formData?.confirmAccountNumber),
    accountType: validateAccountType(formData?.accountType),
    code: validateCode(formData?.code),
    location: validateText(formData?.location),
  };

  if (
    newErrors?.accountName ||
    newErrors?.accountNumber ||
    newErrors?.confirmAccountNumber ||
    newErrors?.accountType ||
    newErrors?.code ||
    newErrors?.location
  ) {
    return { err: newErrors };
  } else {
    return { err: "" };
  }
};