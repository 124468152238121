import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { api } from "services";
import { currencyFormatter } from "utils";

const Coupon = () => {
  const [coupon, setCoupon] = useState([]);
  const [data, setData] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading,setLoading] = useState(true)
  const step_number = 5;

  const fetchCoupon = async () => {
    try {
    const couponData = await api.getOrganizerCoupons();
    setCoupon(couponData);
    let newData = couponData?.slice(0, step_number);
    setData(newData);
    } catch (error) {
      console.log("fetchCoupon error ",error)
    }finally{
      setLoading(false)
    }
  };

  const handlePrev = () => {
    if (currentpagenumber === 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  const handleNext = async () => {
    let finalPagenumber = coupon.length / step_number;
    if (currentpagenumber === finalPagenumber) return;
    setCurentPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  useEffect(() => {
    const start = (currentpagenumber - 1) * step_number;
    const end = currentpagenumber * step_number;
    setData(coupon?.slice(start, end));
  }, [currentpagenumber, coupon]);

  const handlePageNumber = (value) => {
     let isValidNumber = Math.ceil(coupon.length / step_number);
    if (value <= isValidNumber && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  const validatenumber = (value)=>{
    return value ?? 0
  }

  // if(loading) return <Spinner/>

  return (
    <div className="bg-white p-3 border rounded-3  shadow-sm w-100">
    <h3 className="text-center text-md-start  mx-2 mt-2">Coupons</h3>
      <div className="table-responsive rounded-3   mt-4 mb-5">
        <table class="table">
          <thead>
            <tr>
              <th className="px-3" scope="col">#</th>
              <th className="px-3" scope="col">Coupon Code</th>
              <th className="px-3" scope="col">Amount</th>
              <th className="px-3" scope="col">Quantity</th>
              {/* <th className="px-3" scope="col">Number of Coupon Applied</th> */}
              {/* <th className="px-3" scope="col">Number of coupon left</th> */}
              {/* <th className="px-3" scope="col">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {coupon?.map((item, index) => (
              <tr key={index}>
                <th className="px-3" scope="row">
                  {(currentpagenumber - 1) * step_number + index + 1}
                </th>
                <td className="col px-3"><p className={item?.get("isActive")?"text-success":"text-danger"}>{item?.get("code")}</p></td>
                <td className="col px-3">{currencyFormatter(item?.get("amount"))}</td>
                <td className="col px-3">{(item?.get("eventId")?.get("coupons_count")?.[item?.get("code")]) ?? 0}/{item?.get("quantity") + validatenumber(item?.get("eventId")?.get("coupons_count")?.[item?.get("code")])}</td>
                {/* <td className="col px-3">{item?.get("eventId")?.get("coupons_count")?.[item?.get("code")]}</td> */}
                {/* <td className="col px-3">{item?.get("quantity")}</td> */}
                 {/* <td className="col px-3">{item?.get('isActive') ? "True" : "False"}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="">
        <nav aria-label="Page navigation example">
            <ul class="pagination cursor float-end">
              <li className="page-item mx-2">
                <a
                  className="page-link rounded-3"
                  aria-label="Previous"
                  onClick={handlePrev}
                >
                  <span className="text-dark" aria-hidden="true">
                    Prev
                  </span>
                </a>
              </li>
              <li className="page-item cursor" onClick={handleNext}>
                <a
                  className="page-link text-dark mx-2 rounded-3"
                  aria-label="Next"
                >
                  <span aria-hidden="true">
                    Next
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          </div> */}
      </div>
    </div>
  );
};

export default Coupon;