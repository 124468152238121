
export const addMinutes = (date, minutes) => {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
}

export const currencyFormatter = (amount) => {
    var formattedCurrency = new Intl.NumberFormat("nb-NB", {
        maximumFractionDigits: 0,
        style: 'currency', 
        currency: 'NOK',
        currencyDisplay: "narrowSymbol"
      }).format(amount);

    return formattedCurrency
}