import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axios from "axios";
import { auth } from "services";

const userStore = (set) => ({
  users: [],
  couponsData:[],
  singleUser: null,

  //getSingleUser Data
  getSingleUser: async (id) => {
    try {
      const singleUserData = await axios.get(
        `https://661517662fc47b4cf27df5ac.mockapi.io/crud/${id}`
      );

      // return singleUserData.data;

      set(() => ({
        singleUser: singleUserData?.data,
      }));
    } catch (error) {
      console.log("Get SIngle User Errro ", error);
    }
  },

  // Fetch user profile and store it in users
  fetchProfile: async () => {
    try {
      const res = await auth.getProfileData();
      set((state) => ({
        users: [...state.users, res],
      }));
    } catch (error) {
      console.log("Profile fetch error", error);
      throw error;
    }
  },

  //method to add the coupons in couponsData store
  addCoupons: async(data)=>{
    console.log("addCoupons data ",data)
   set((state)=>({
    couponsData:[...state.couponsData, data]
   }))
  }
});

const useStore = create(devtools(userStore, { name: "user" }));

export default useStore;