import { useTranslation } from "react-i18next";
import Tono from "./Tono";

const About = () => {
const {t} = useTranslation('about')
    return (
        <div className="container my-5">
            <div className="row content">
                <h1 className="text-center py-3 fw-bold">{t('title')}</h1>
                <br />
                <div className="text-start justify-content-center fs-5">
              <p>{t('description')}</p>
                
                <div className="fs-3 fw-bold">Teamet</div><br />
                <ul>
                    <li><b>Sondre Pedersen</b> - {t('manager')}</li><br />
                    <li><b>Jakob Wredstrøm</b> - {t('assistantManager')}</li><br />   
                    <li><b>Abhijeet Goel</b> - {t('chief')}</li><br />
                    <li><b>Tajdar Khan</b> - {t('productManager')}</li><br />
                    <li><b>Amplitude Ventures  AS</b> - {t('venture')}<br/></li>
                </ul>
                <br/>
                <div className="fs-3 fw-bold">{t('contactus')}</div><br />
                    <ul>
                        {/* <li>Telefon: +47 921 57 656</li> */}
                        <li>{t('email')}<a href="sondre@mintix.no" class="text-reset">sondre@mintix.no</a></li>
                    </ul><br/>
                    <p>{t('message')}</p>
                </div>   
            </div>
        </div>
    )
}

export default About;