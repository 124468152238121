import React, { useEffect, useRef, useState } from "react";
import { api } from "services";
import ConfirmDelete from "../popups/confirmDelete";
import norway from "icons/norway.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateCouponForm,
  ValidateField,
} from "views/validation/Validations";
import { useTranslation } from "react-i18next";

const AddCoupon = ({ eventData, handleGoBack }) => {
  const [coupon, setCoupon] = useState({ type: "0" });
  const [eventCoupons, setEventCopons] = useState([]);
  const [couponsData, setCouponsData] = useState([]);
  const [action, setAction] = useState("");
  const [couponId, setCouponId] = useState("");
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation("addcoupon");
  const { id } = useParams();
  const navigate = useNavigate();

  const eventId = id;
  const closeButtonRef = useRef(null);

  //get the all coupons data based on eventId()
  const fetchData = async (id) => {
    try {
      // const couponsData = await api.getCoupon();
      if (id) {
        const couponsData = await api.getCouponbyEventId(id);
        // console.log("couponsData ",couponsData)
        setEventCopons(couponsData);
      }
    } catch (error) {
      console.log("fetchData error ", error);
    }
  };

  //get the coupon details based on coupon Id
  const fetChDatabyId = async () => {
    try {
      const res = await api.getCouponbyId(couponId);
      setCoupon(res?.attributes);
    } catch (error) {
      console.log("fetChDatabyId error ", error);
    }
  };

  //intially when page loads it wil feth events based on eventId
  useEffect(() => {
    if (eventData?.id) {
      fetchData(eventData?.id);
    }
  }, []);

  //when coupon Id is present then it will fetch the coupon data based on coupon Id else it will fetch all coupon data based on Eventid
  useEffect(() => {
    if (couponId) {
      fetChDatabyId();
    } else {
      if (eventId) {
        fetchData(eventId);
      }
    }
  }, [couponId, edit]);

  const removeSpace = (str) => {
    let newStr = str?.toString()?.replaceAll(" ", "");
    return newStr;
  };

  const norwayCurrency = (num) => {
    let numStr = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numStr;
  };

  //handle coupon fields
  const updateCoupon = (e) => {
    const { name, value } = e.target;
    const err = ValidateField(name, value);
    if (!err) {
      setErrors((prev) => ({
        ...prev,
        [name]: err,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: err,
      }));
    }

    if (name == "amount" || name == "quantity") {
      let value2 = removeSpace(value);
      if (/^(kr|k|r)?\s*(0|10000|[1-9]\d{0,3})?(,\d{0,2})?$/.test(value2)) {
        if (name === "amount") {
          let currency = norwayCurrency(value);
          setCoupon({
            ...coupon,
            [name]: currency,
          });
        } else {
          setCoupon({
            ...coupon,
            [name]: value,
          });
        }
      }
    } else {
      setCoupon({
        ...coupon,
        [name]: value,
      });
    }
  };

  //method to return the coupon name based on type
  const getcouponName = (number) => {
    if (number == 0) {
      return "All";
    } else if (number == 1) {
      return t("ordinary");
    } else if (number == 2) {
      return t("student");
    }
  };

  //handle method to update the switch state that is coupon On or OFF
  const toggleCouponStatus = async (id) => {
    let updatedCoupon;
    let newCoupon = eventCoupons?.filter((item) => {
      if (item.id === id) {
        updatedCoupon = item;
        return item.set("isActive", !item.get("isActive"));
      } else {
        return item;
      }
    });

    try {
      setEventCopons(newCoupon);
      const res = await api.toggleSwitch(id, updatedCoupon);
    } catch (error) {
      console.log("updating coupon status Error ", error);
    }
  };

  //get the coupon type name based on coupon type number in adding coupon form
  const getCouponLabel = (type) => {
    if (type == "0") {
      return "All";
    } else if (type == "1") {
      return "Ordinary";
    } else if (type == "2") {
      return "Student & U18";
    }
  };

  //method to make event live
  const handlegoLive = async () => {
    // const res = await api.addEvent(eventData);
    // const resdata = await api.postEventLive(res?.id);
    const res = await api.postEventLive(eventData?.id);
    if (res) {
      navigate("/events");
      //  window.location.reload();
    }
  };

  //method to add the coupons in database
  const handleSaveEvent = async () => {
    const res = await api.addCoupons(couponsData, eventData?.id);
    if (res.success) {
      navigate("/events");
    }
  };

  //handle update coupon method based on coupon id
  const handleUpdateCoupon = async () => {
    const { err } = validateCouponForm(coupon);
    if (!err) {
      try {
        let newCoupon = { ...coupon, amount: removeSpace(coupon?.amount) };
        const res = await api.updateCoupon(couponId, newCoupon, eventData?.id);
        if (res?.code !== 101) {
          // This will trigger the modal close
          if (closeButtonRef.current) {
            closeButtonRef.current.click();
          }
          console.log("coupon is ", coupon);
          setCoupon({ type: "0" });
          // fetChDatabyId(couponId);
          setCouponId("");
          setEdit(false);
          // fetchData(eventData?.id);
        } else {
          setErrors((prev) => ({
            ...prev,
            ["couponError"]: res?.message,
          }));
        }
      } catch (error) {
        console.log("Updating coupon Errror ", error);
      }
    } else {
      setErrors(err);
    }
  };

  //method to check the duplicate coupons in both database and local state(couponsData)
  const handleAddAllCoupons = async () => {
    const { err } = validateCouponForm(coupon);
    if (!err) {
      try {
        let newCoupon = { ...coupon, amount: removeSpace(coupon?.amount) };
        const res = await api.checkCoupons(newCoupon?.code, eventData?.id);
        if (res?.code !== 101) {
          // Error code 101 means coupon already exists
          let isCoupon = couponsData.filter(
            (item) => item?.["code"] === coupon?.code
          );

          if (!isCoupon.length) {
            setCouponsData([...couponsData, newCoupon]);
            setCoupon({ type: "0" });

            // This will trigger the modal close
            if (closeButtonRef.current) {
              closeButtonRef.current.click();
            }
          } else {
            setErrors((prev) => ({
              ...prev,
              couponError: "Coupon already exist",
            }));
          }
          setCouponId("");
        }
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          couponError: error?.message,
        }));
        console.log("Adding new Coupon Error ", error);
      }
    } else {
      console.log("error ", err);
      setErrors(err);
    }
  };

  //method to delete the coupons in localstate (couponsDate)
  const handleDeletecoupon = (code) => {
    let filteredCoupons = couponsData?.filter((item) => item?.code !== code);
    setCouponsData(filteredCoupons);
  };

  //handle delete coupon method based on coupon Id
  const deleteCoupon = async () => {
    try {
      const res = await api.deleteCoupon(couponId);
      setAction(null);
      setCoupon({ type: 0 });
      setCouponId(null);
      await fetchData();
    } catch (error) {
      console.log("Coupon delete Error ", error);
    }
  };

  const handleCancel = () => {
    navigate("/events");
  };

  //reset the all states when model cose
  const handleClose = () => {
    setCoupon({ type: 0 });
    setAction(null);
    setErrors("");
    setCouponId(null);
    setEdit(false);
  };

  //calling the parent component method that is handleGoBack
  const goBack = () => {
    handleGoBack();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="row py-3">
          <div className="col-1">
            <i
              className="bi bi-arrow-left-circle h1"
              onClick={goBack}
              role="button"
            ></i>
          </div>
          <div className="col-10">
            <h1 className="text-left">
              <b>{t("couponTable.title")}</b>
            </h1>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "8rem" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              data-bs-whatever="@mdo"
            >
              {t("addCouponbtn")}
            </button>
          </div>
        </div>
        <hr />
        {/* </div> */}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("couponTable.name")}</th>
                  <th scope="col">{t("couponTable.type")}</th>
                  <th scope="col">{t("couponTable.quantity")}</th>
                  <th scope="col">{t("couponTable.price")}</th>
                  <th scope="col">{t("couponTable.status")}</th>
                  <th scope="col">{t("couponTable.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {eventCoupons &&
                  eventCoupons?.map((coupon, j) => {
                    return (
                      <tr key={j}>
                        <th scope="row">{++j}</th>
                        <td>{coupon?.get("code")}</td>
                        <td>{getcouponName(coupon?.get("type"))}</td>
                        <td>{coupon?.get("quantity")}</td>
                        <td>{coupon?.get("amount")}</td>
                        <td>
                          <div class="form-check form-switch">
                            <input
                              className={`${
                                coupon?.get("isActive")
                                  ? "bg-success"
                                  : "bg-secondary"
                              } form-check-input`}
                              name="isActive"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={coupon?.get("isActive") || ""}
                              onChange={() => toggleCouponStatus(coupon.id)}
                            />
                          </div>
                        </td>
                        <td align="">
                          <>
                            <i
                              type="button"
                              className="bi bi-pencil h4 mx-2"
                              onClick={() => {
                                setCouponId(coupon?.id);
                                fetChDatabyId(coupon?.id);
                                setEdit(true);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              data-bs-whatever="@mdo"
                            ></i>
                            <i
                              className="bi bi-trash h4 mx-2"
                              role="button"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmDeleteModal"
                              onClick={() => {
                                setCouponId(coupon?.id);
                                setAction({
                                  actionName: deleteCoupon,
                                  actionBody: `${t("deleteMessage")}`,
                                  actionTitle: `${t("deleteCoupon")}`,
                                });
                              }}
                            ></i>
                          </>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              {couponsData &&
                couponsData?.map((coupon, j) => {
                  return (
                    <tr key={j}>
                      <th scope="row">{++j}</th>
                      <td>{coupon?.code}</td>

                      <td>{getcouponName(coupon?.type)}</td>

                      <td>{coupon?.quantity}</td>

                      <td>{coupon?.amount}</td>

                      <td className="text-success fw-bold">Active</td>
                      <td align="center">
                        <i
                          className="bi bi-trash h4  cursor"
                          onClick={() => handleDeletecoupon(coupon?.code)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              <tbody></tbody>
            </table>
          </div>
        </div>
        <div className="col-4">
          <button
            className="btn btn-outline-secondary btn-block py-3 fw-bold w-100"
            onClick={handleCancel}
          >
            {t("cancelbtn")}
          </button>
        </div>
        <div className="col-4 ">
          <button
            disabled={couponsData.length === 0}
            className="btn btn-outline-secondary btn-block py-3 fw-bold w-100"
            onClick={handleSaveEvent}
          >
            {t("savebtn")}
          </button>
        </div>
        <div className="col-4 ">
          <button
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
           disabled={eventData?.get("isLive") === 1}
            className="btn btn-outline-secondary fw-bold  py-3 px-5 w-100"
            onClick={(e) => {
              setAction({
                actionName: handlegoLive,
                actionBody: "Are You sure You wan to make this Event Live",
                actionTitle: "Go Live Event",
              });
            }}
          >
            {t("goLivebtn")}
          </button>
        </div>
      </div>
      {/* add coupon model */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="couponModalLabel">
                {t("addCouponbtn")}
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={handleClose}
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row mt-3">
                  <div className="col-md-12 col-12 offset-md-1">
                    <div className="">
                      <div className="form-check px-0 col-md-10 col-12 my-2 form-check-inline">
                        <label className="form-check-label fw-bolder mb-2">
                          {t("addCoupon.code")}
                        </label>
                        <div className="input-group mb-0">
                          <input
                            type="text"
                            name="code"
                            disabled={edit}
                            onChange={updateCoupon}
                            value={coupon?.code || ""}
                            className="form-control py-2 rounded-end bg-transparent border-secondary-subtle"
                            placeholder={t("placeholder.code")}
                            aria-label=""
                            aria-describedby="basic-addon2"
                          />
                        </div>
                        <p className="text-danger my-2">
                          {errors && errors?.code}
                        </p>
                      </div>

                      <div className="form-check px-0 col-md-10 col-12 my-2 form-check-inline">
                        <label className="form-check-label fw-bolder mb-2">
                          {t("addCoupon.quantity")}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantity"
                            onChange={updateCoupon}
                            value={coupon?.quantity || ""}
                            className="form-control py-2 rounded-end bg-transparent border-secondary-subtle"
                            placeholder={t("placeholder.quantity")}
                            aria-label=""
                            aria-describedby="basic-addon2"
                          />
                        </div>
                        <p className="text-danger mb-2">
                          {errors && errors?.quantity}
                        </p>
                      </div>

                      <div className="form-check px-0 col-md-10 col-12 my-2 form-check-inline">
                        <label className="form-check-label fw-bolder mb-2">
                          {t("addCoupon.cost")}
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="amount"
                            value={coupon?.amount || ""}
                            onChange={updateCoupon}
                            className="form-control py-2 rounded-end bg-transparent border-secondary-subtle"
                            placeholder={t("placeholder.cost")}
                            aria-label=""
                            aria-describedby="basic-addon2"
                          />
                          <span
                            className="input-group-text px-1 py-2 position-absolute end-0"
                            id="basic-addon2"
                          >
                            <img
                              className="rounded flag-logo"
                              src={norway}
                              alt=""
                            />
                            NOK
                          </span>
                        </div>
                        <p className="text-danger">
                          {errors && errors?.amount}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-md-5 col-12 my-2 form-check-inline">
                        <label htmlFor="" className="text-dark fw-bold mb-2">
                          {t("addCoupon.type")}
                        </label>
                        <div class="dropdown">
                          <button
                            class="btn border dropdown-toggle py-2"
                            type="button"
                            id="multiSelectDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {getCouponLabel(coupon?.type)}
                          </button>
                          <ul
                            class="dropdown-menu px-2"
                            aria-labelledby="multiSelectDropdown"
                          >
                            <li className="d-flex mx-2">
                              <input
                                type="checkbox"
                                className="cursor mx-2"
                                name="type"
                                onChange={updateCoupon}
                                value="1"
                                checked={coupon?.type == "1"}
                              />
                              <label>Ordinary</label>

                              {/* <label> {t("ordinary")}</label> */}
                            </li>
                            <li className="d-flex mx-2">
                              <input
                                type="checkbox"
                                className="cursor mx-2"
                                name="type"
                                onChange={updateCoupon}
                                value="2"
                                checked={coupon?.type == "2"}
                              />
                              <label>Student & U18</label>

                              {/* <label> {t("student")}</label> */}
                            </li>
                            <li className="d-flex mx-2">
                              <input
                                type="checkbox"
                                className="cursor mx-2"
                                name="type"
                                onChange={updateCoupon}
                                value="0"
                                checked={coupon?.type == "0"}
                              />

                              {/* <label>{t("companion")}</label> */}
                              <label>All</label>
                            </li>
                          </ul>
                        </div>
                        <p className="text-danger">{errors && errors?.type}</p>
                        <p className="text-danger">
                          {errors && errors?.couponError}
                        </p>
                      </div>
                    </div>
                    <div className="form-check px-0 col-md-3 col-12 my-2 form-check-inline d-flex align-content-end">
                      {edit ? (
                        <>
                          <button
                            className="btn btn-secondary my-3 eventbtn fw-bold"
                            onClick={handleUpdateCoupon}
                            // data-bs-dismiss="modal"
                          >
                            {t("updateCouponbtn")}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            // onClick={handleAddCoupon}
                            onClick={handleAddAllCoupons}
                            className="btn btn-secondary my-4 eventbtn fw-bold"
                          >
                            {t("addCouponbtn")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {couponId ? (
        <ConfirmDelete handleAction={deleteCoupon} action={action} />
      ) : (
        <ConfirmDelete handleAction={action?.actionName} action={action} />
      )}
    </div>
  );
};

export default AddCoupon;