import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faLocationDot } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import { auth } from "services";

const Event = (props) => {
  const id = props.id;
  const event = props.event;

  const handleLoginCheck = (id) => {
    const user = auth.fetchUser();
    return user;
  };

  return (
    <Link to={`event/${event.id}`} className="card-link text-decoration-none">
      {/* onClick={()=>handleLoginCheck(event?.id)} */}
      {/* to={handleLoginCheck() ? `event/${event.id}`:`/login`}  */}

      <div className="card shadow h-100">
        <img src={event?.get("cover_photo")} />
        <div className="card-body d-flex flex-column justify-content-between">
          <div>
            <h4>
              <b>{event.get("title")}</b>
            </h4>
            <p>
              {event.get("date").toDateString()}
              <br />
            </p>
          </div>

          <div className="fw-lighter fst-italic">
            <FontAwesomeIcon icon={faLocationDot} className="px-1 fs-5" />
            <p className="text-truncate" style={{ whiteSpace: "nowrap" }}>
              {event.get("address")}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Event;