import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { api, auth } from "services";
import { currencyFormatter } from "utils";

const Payment = () => {
  const [bookings, setBookings] = useState([]);
  const [data, setData] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  //It is the number of rows to show in a Table
  const step_number = 5;

  //get fetch bookings based on organizerId or Admin Id
  const fetchBookings = async () => {
    try {
      const user = auth.fetchUser();
      const bookings = await api.getAllbookings(user?.id);

      // Function to sort bookings based on creation date in descending order
      const sortingData = (bookings) => {
        return bookings?.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      };

      const sortedBookings = sortingData(bookings);
      setBookings(sortedBookings);

      // setBookings(bookings);
    } catch (error) {
      console.log("fetchBookings error ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  //It will set the currentpage to previous page
  const handlePrev = () => {
    if (currentpagenumber === 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  //It will set  the current page to next page
  const handleNext = async () => {
    let finalPagenumber = bookings?.length / step_number;
    if (currentpagenumber === finalPagenumber) return;
    setCurentPageNumber((prev) => prev + 1);
  };

  //It will set Data according to the step number
  useEffect(() => {
    const start = (currentpagenumber - 1) * step_number;
    const end = currentpagenumber * step_number;
    setData(bookings?.slice(start, end));
  }, [currentpagenumber, bookings]);

  //handle page to set current page number
  const handlePageNumber = (value) => {
    let isValidNumber = Math.ceil(bookings?.length / step_number);
    if (value <= isValidNumber && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  // if (loading) return <Spinner />;

  return (
    <div className="p-3 bg-white border rounded-3 mt-2 shadow-sm">
      <h3 className="text-center text-md-start my-3 mx-2">Payments</h3>
      <div className="table-responsive  rounded-3  mt-4 mb-5">
        <table class="table rounded-2">
          <thead className="">
            <tr>
              <th scope="col">#</th>
              {/* <th scope="col">User Name</th> */}
              <th scope="col">Event Name</th>
              {/* <th scope="col">Ticket Type</th> */}
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              {/* <th scope="col">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {bookings?.map((item, index) => (
              <tr>
                <th scope="col">
                  {(currentpagenumber - 1) * step_number + index + 1}
                </th>
                {/* <td>{item?.get("userId")?.get("username")}</td> */}
                <td scope="col">{item?.get("eventId")?.get("title")}</td>
                <td scope="col">
                  <p
                    className={
                      item?.get("status") === "complete"
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {currencyFormatter(item?.get("amount"))}
                  </p>
                </td>
                {/* <td scope="col">{item?.get("createdAt").toString()}</td> */}
                <td scope="col">{new Date(item?.get("createdAt").toString()).toUTCString()}</td>
                {/* <td scope="col">{item?.get("status")}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div>
        <nav aria-label="Page navigation example">
            <ul class="pagination cursor float-end">
              <li className="page-item mx-2">
                <a
                  className="page-link rounded-3"
                  aria-label="Previous"
                  onClick={handlePrev}
                >
                  <span className="text-dark" aria-hidden="true">
                    Prev
                  </span>
                </a>
              </li>

              <li className="page-item cursor" onClick={handleNext}>
                <a
                  className="page-link text-dark mx-2 rounded-3"
                  aria-label="Next"
                >
                  <span aria-hidden="true">
                    Next
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    </div>
  );
};

export default Payment;
