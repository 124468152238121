import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { auth } from "services";

const Profile = () => {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const { t } = useTranslation("profile");

  const fetchProfile = async () => {
    try {
      const res = await auth.getProfileData();
      setProfile(res);
    } catch (error) {
      console.log("profile res error ", error);
    }
  };

  useEffect(() => {
    const user = auth.fetchUser();
    setUser(user?.attributes);
    fetchProfile();
  }, []);

  const Gender = (value)=>{
    if(value === "other"){
      return t('myAccount.others')
    }else{
      return value
    }
  }

  return (
    <div>
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td className="fw-bolder" aria-placeholder="name">
              {t("myAccount.name")}
            </td>
            <td>{`${profile?.firstname} ${profile?.lastname}`}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="email">
              {t("myAccount.email")}
            </td>
            <td>{user?.email}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="gender">
              {t("myAccount.sex")}
            </td>
            <td>{Gender(profile?.gender)}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="country">
              {t("myAccount.country")}
            </td>
            <td>{profile?.country}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="address">
              {t("myAccount.address")}
            </td>
            <td>{profile?.address}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="phone">
              {t("myAccount.telephone")}
            </td>
            <td>{profile?.phone}</td>
          </tr>
          <tr>
            <td className="fw-bolder" aria-placeholder="dateofbirth">
              {t("myAccount.dateofBirth")}
            </td>
            <td>
              <DatePicker
                selected={profile?.dateOfBirth}
                dateFormat="dd-MM-yyyy"
                name="dateOfBirth"
                id="date"
                className="form-control w-100 bg-transparent border-0 px-0"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Profile;