import React, { useLayoutEffect, useRef, useState } from "react";
import AddEvent from "./AddEvent";
import AddCoupon from "./AddCoupon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckoutStepper = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setmargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const [eventData, setEventData] = useState({});
  const {t} = useTranslation('common');
  const navigate = useNavigate();
  const stepRef = useRef([]);

  useLayoutEffect(() => {
    if (stepRef.current.length === CHECKOUT_STEPS.length) {
      setmargins({
        marginLeft: stepRef.current[0].offsetWidth / 2,
        marginRight: stepRef.current[CHECKOUT_STEPS.length - 1].offsetWidth / 2,
      });
    }
  }, []);

  const handleNext = (eventDetails) => {
    //we are getting event details from child component
    setEventData(eventDetails);
    setCurrentStep((prevStep) => {
      if (prevStep === CHECKOUT_STEPS.length) {
        setIsComplete(true);
        navigate("/events");
        return prevStep;
      } else {
        return prevStep + 1;
      }
    });
  };
  const handleGoBack = ()=>{
   setCurrentStep((prevStep)=>{
    if(prevStep >= 1 || prevStep <= CHECKOUT_STEPS.length){
      return prevStep-1
    }else{
      return
    }
   })
  }

  const calculateProgressBarWidth = () => {
    let wd = ((currentStep - 1) / (CHECKOUT_STEPS.length - 1)) * 100;
    return wd;
  };

  const CHECKOUT_STEPS = [
    {
      name: t('addEvent'),
      Component: <AddEvent handleNext={handleNext} />,
    },
    {
      name: t('addCoupon'),
      Component: <AddCoupon eventData={eventData} handleGoBack={handleGoBack} />,
    },
  ];

  return (
    <>
      <div className="container" >
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="stepper my-2">
              {CHECKOUT_STEPS.map((item, index) => (
                <div
                  key={index}
                  ref={(el) => (stepRef.current[index] = el)}
                  className={`step ${
                    currentStep > index + 1 || isComplete ? "complete" : ""
                  } ${currentStep === index + 1 ? "active" : ""}`}
                >
                  <div className="step-number">
                    {currentStep > index + 1 || isComplete ? (
                      <span>&#10003;</span>
                    ) : (
                      index + 1
                    )}
                  </div>
                  <div className="step-name">{item.name}</div>
                </div>
              ))}

              <div
                className="progress-bar"
                style={{
                  width: `calc(100% - ${
                    margins.marginLeft + margins.marginRight
                  }px)`,
                  marginLeft: margins.marginLeft,
                  marginRight: margins.marginRight,
                }}
              >
                <div
                  className="progress"
                  style={{ width: `${calculateProgressBarWidth()}%` }}
                ></div>
              </div>
            </div>
            {/* {!isComplete && (
              <button
                className="btn btn-secondary"
                style={{ width: "4rem" }}
                onClick={handleNext}
              >
                {currentStep === CHECKOUT_STEPS.length ? "Finish" : "Next"}
              </button>
            )}
            <button className="btn btn-secondary" onClick={handleGoBack}>back</button> */}
            <div>
              <p>{CHECKOUT_STEPS[currentStep - 1]?.Component}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutStepper;
