import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const environment = process.env.NODE_ENV || 'development'; // Default to 'development' if not set
let LANGUAGE_ID = environment == 'development'
? "en"
: "nw";

const setDefaultLanguage = ()=>{
  let defaultLanguage = "";
  let lng = JSON.parse(localStorage?.getItem("language"));
  // console.log("lng ",lng)
  // let lng = "en"
  if(lng){
    // console.log("if ",lng)
    defaultLanguage=lng
    LANGUAGE_ID=lng
  }else{
    // console.log("else ",lng)
    defaultLanguage=LANGUAGE_ID;
    LANGUAGE_ID=lng;
  }
  // console.log("defaultLanguage ",defaultLanguage)
  return defaultLanguage;
}
// setDefaultLanguage()
// console.log("LANGUAGE_ID ",)
const availableLanguages = ['en','nw'];
const option={
    order:['navigator','htmlTag','path','subdomain'],
    checkWhitelist:true
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug:true,
    fallbackLng: 'en',
    returnObjects:true,
    // lng:LANGUAGE_ID,
    lng:setDefaultLanguage(),
    debug: true,
    whitelist:availableLanguages,
    detection:option,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns:["common","home"],
    defaultNS:'common',
    backend:{
      loadPath:'/locales/{{lng}}/{{ns}}.json'
    },
    interpolation:{
      escapeValue:false
    }
  });


export default i18n;