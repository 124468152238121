import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, auth } from "services";
import { currencyFormatter } from "utils";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const step_number = 5;

  //sorting events based on date in ascending order based on creation event time
  const sortingData = (events) => {
    const publshedData = events?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    });
    return publshedData;
  };

  //events filtered based on the events which are live and completed but not drafted
  const filteredEvents = (events) => {
    let filterdevents = events?.filter((item) => item?.get("isLive") !== 0);
    let sortedEvents = sortingData(filterdevents);
    setEvents(sortedEvents);
  };

  //fetch the events based on user role
  const fetchEvents = async () => {
    try {
      const role = await api.getCurrentUserRoles();
      if (role === "admin") {
        const res = await api.getEvents();
        filteredEvents(res);
      }
      if (role === "organizer") {
        const user = auth.fetchUser();
        const res = await api.organizerEvents(user?.id);
        filteredEvents(res);
      }
    } catch (error) {
      console.log("fetchEvents error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrev = () => {
    if (currentpagenumber === 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  const handleNext = async () => {
    let finalPagenumber = events.length / step_number;
    if (currentpagenumber === finalPagenumber) return;
    setCurentPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    const start = (currentpagenumber - 1) * step_number;
    const end = currentpagenumber * step_number;
    setData(events?.slice(start, end));
  }, [currentpagenumber, events]);

  const handlePageNumber = (value) => {
    let isValidNumber = Math.ceil(events.length / step_number);
    if (value <= isValidNumber && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  if (loading) return <Spinner />;
  return (
    <div className="bg-white border rounded-3 shadow-sm p-3 w-100">
      <div className="d-flex justify-content-between">
        <h3 className="text-center text-md-start  mx-2">Events</h3>
        <a className="text-center align-content-center" href="/events">
          View all
        </a>
      </div>

      <div className="mb-5 table-responsive rounded-3  mt-4">
        <table className="table">
          <thead>
            <tr>
              <th className="px-3" scope="col">
                #
              </th>
              <th scope="col">Event Name</th>
              <th scope="col">Tickets</th>
              {/* <th scope="col">Ticket Left</th> */}
              {/* <th scope="col">Sold Tickets</th> */}
              <th scope="col">Amount</th>
              <th scope="col">Tono Report</th>
            </tr>
          </thead>
          <tbody>
            {events?.map((item, index) => (
              <tr>
                <th className="px-3" scope="row">
                  {(currentpagenumber - 1) * step_number + index + 1}
                </th>
                <td>
                  <p className="my-0 py-0">{item?.get("title")}</p>
                  {item?.get("isLive") === 1 ? (
                    <p className="my-0 py-0 text-success">Live</p>
                  ) : (
                    <p className="my-0 py-0  text-danger">Completed</p>
                  )}
                </td>
                <td>
                  {item?.get("bookings_count")?.sold_tickets}/
                  {item?.get("bookings_count")?.totalTickets}
                </td>
                {/* <td>{item?.get("max_tickets")}</td> */}
                {/* <td>{item?.get("bookings_count")?.sold_tickets}</td> */}
                <td>
                  {currencyFormatter(
                    item?.get("bookings_count")?.total_tickets_amount ?? 0
                  )}
                </td>
                <td>
                  {(item?.get("send_email") && item?.get("isLive") === 2) ? (
                    <button
                      type="button"
                      className="btn btn-outline-secondary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Submitted
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-secondary"
                     disabled={item?.get("isLive")===1}
                      onClick={() => navigate(`/tono/${item?.id}`)}
                    >
                      Generate Tono Report
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          {/* <nav aria-label="Page navigation example">
            <ul class="pagination cursor float-end">
              <li className="page-item mx-2">
                <a
                  className="page-link rounded-3"
                  aria-label="Previous"
                  onClick={handlePrev}
                >
                  <span className="text-dark" aria-hidden="true">
                  
                    Prev
                  </span>
                </a>
              </li>
             

              <li className="page-item cursor" onClick={handleNext}>
                <a
                  className="page-link text-dark mx-2 rounded-3"
                  aria-label="Next"
                >
                  <span aria-hidden="true">
                  
                    Next
                  </span>
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>

      {/* Model */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="fw-bolder">Tono Report</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Tono Report Generated Please Check Your Mail
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;