import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "services";
import {
  validatePassword,
  validatePasswordForm,
} from "views/validation/Validations";

const Password = forwardRef((props, ref) => {
  const [password, setPassword] = useState({});
  const [error, setError] = useState({});

  const { t } = useTranslation("profile");
  const navigate = useNavigate();

  // const updateProfile =()=>{
  //   console.log("updateProfile ")
  // }

  //hideandshow method for password field
  const handleShowHidePassword = (eleId) => {
    const ele = document.getElementById(eleId);
    if (ele.type === "text") {
      ele.type = "password";
    } else {
      ele.type = "text";
    }
  };

  //update the new password fields
  const handlePassword = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const err = validatePassword(value);

    setError((prev) => ({
      ...prev,
      [name]: err,
    }));

    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //submti new Password
  const submitPassword = async () => {
    // const err = validatePasswordForm(password);

    // if (!err) {
    //   if (password?.newPassword !== "" && password?.confirmPassword !== "") {
    //     if (password?.newPassword === password?.confirmPassword) {
    //       try {
    //         const isAuthenticatd = await auth.checkUserAuthenticated(password?.oldPassword);
    //         console.log("isAuthenticatd ",isAuthenticatd);
    //         if(isAuthenticatd){
    //           const res = await auth.changePassword(password?.newPassword);
    //           if (res) {
    //             navigate("/profile");
    //             // window.location.reload();
    //           }
    //         }
    //       } catch (error) {
    //         if(error?.code === 101){
    //           setError((prev) => ({
    //             ...prev,
    //            ["oldPassword"]:"Old Password doesn't matched"
    //           }));
    //         }
    //         console.log("error password ", error);
    //       }
    //     } else {
    //       setError((prev) => ({
    //         ...prev,
    //         ["confirmPassword"]: "password not matched",
    //       }));
    //       // console.log("password not matched");
    //     }
    //   }
    // } else {
    //   setError(err);
    //   console.log("Error is present ", error);
    // }

    const err = validatePasswordForm(password);
    if (!err) {
      if (password?.newPassword !== "" && password?.confirmPassword !== "") {
        if (password?.newPassword === password?.confirmPassword) {
          try {
            const isAuthenticatd = await auth.checkUserAuthenticated(
              password?.oldPassword
            );
            if (isAuthenticatd) {
              if (password?.oldPassword == password?.newPassword) {
                setError((prev) => ({
                  ...prev,
                  ["confirmPassword"]:
                    "New Password shouldn't be same as Old Password",
                }));
                console.log("password ", password);
              } else {
                const res = await auth.changePassword(password?.newPassword);
                if (res) {
                  navigate("/login");
                }
              }
            } else {
              setError((prev) => ({
                ...prev,
                ["oldPassword"]: "Old Password does not matched",
              }));
            }
          } catch (error) {
            console.log("error password ", error);
            if (error?.code === 101) {
              setError((prev) => ({
                ...prev,
                ["oldPassword"]: "Old Password does not matched",
              }));
            }
          }
        } else {
          setError((prev) => ({
            ...prev,
            ["confirmPassword"]: "Password doesnt matched",
          }));
        }
      }
    } else {
      setError(err);
      console.log("Error is present ", error);
    }
  };

  //this method is used to call the function in child fom parent
  useImperativeHandle(ref, () => ({
    callChildFunction: submitPassword,
  }));

  return (
    <div className="my-2">
      <form action="" className="needs-validation">
        <div class="col-12 mb-3">
          <label
            for="oldPassword"
            class="form-label fw-bold"
            aria-placeholder="oldpassword"
          >
            {t("changePassword.oldPassword")}
          </label>
          <div className="position-relative">
            <input
              type="password"
              name="oldPassword"
              value={password?.oldPassword || ""}
              class="form-control w-100 my-2"
              id="oldPassword"
              onChange={handlePassword}
              required
            />
            <span
              className="position-absolute top-0  end-0 p-2"
              style={{ cursor: "pointer" }}
            >
              <i
                class="bi bi-eye"
                onClick={() => handleShowHidePassword("oldPassword")}
              ></i>
            </span>
          </div>
          {error?.oldPassword && (
            <p className="text-danger">{error?.oldPassword}</p>
          )}
        </div>

        <div class="col-12 mb-3">
          <label
            for="newPassword"
            class="form-label fw-bold"
            aria-placeholder="newpassword"
          >
            {t("changePassword.newPassword")}
          </label>
          <div className="position-relative">
            <input
              type="password"
              name="newPassword"
              class="form-control my-2"
              id="newPassword"
              value={password?.newPassword || ""}
              onChange={handlePassword}
              required
            />
            <span
              className="position-absolute top-0 end-0 p-2"
              style={{ cursor: "pointer" }}
            >
              <i
                class="bi bi-eye"
                onClick={() => handleShowHidePassword("newPassword")}
              ></i>
            </span>
          </div>
        </div>
        {error?.newPassword && (
          <p className="text-danger">{error?.newPassword}</p>
        )}

        <div class="col-12 mb-3">
          <label
            for="confirmPassword"
            class="form-label fw-bold"
            aria-placeholder="confirmpassword"
          >
            {t("changePassword.confirmPassword")}
          </label>
          <div className="position-relative">
            <input
              type="text"
              name="confirmPassword"
              class="form-control my-2"
              id="confirmPassword"
              value={password?.confirmPassword || ""}
              onChange={handlePassword}
              required
            />
          </div>
        </div>
        {error?.confirmPassword && (
          <p className="text-danger">{error?.confirmPassword}</p>
        )}
      </form>
    </div>
  );
});

export default Password;
