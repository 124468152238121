import Spinner from "components/shared/Spinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { api, stripe } from "services";

const Return = () => {
  const [status, setStatus] = useState(null);
  const { t } = useTranslation("return");
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleTicketPage = async () => {
    console.log("handleTicketPage ")
    setTimeout(() => {
    //   <Navigate to="/tickets" />;
    navigate("/tickets")
    }, 2000); // 2000 milliseconds = 2 second
  };

  const createTicket = async () => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");
      const bookingId = urlParams.get("booking_id");

      let bookingData = JSON.parse(localStorage.getItem("booking"));
      const data = await stripe.getPaymentStatus(sessionId);
      setStatus(data?.status);
      if (data?.status === "complete") {
        //Generate & Mail Ticket after uploading it to aws s3.
        await api.createTicket(bookingId);
        //update the event ticket count and ticketTypes count
        const result = await api.eventTicketUpdate(bookingData);
        localStorage.removeItem("booking");
      }
      // setStatus(data?.status);

      // stripe.getPaymentStatus(sessionId)
      // .then((data) => {
      //     setStatus(data.status);

      //     if(data.status == "complete"){
      //         //Generate & Mail Ticket after uploading it to aws s3.
      //       api.createTicket(bookingId);
      //         //Reduce no. of tickets

      //         //Reduce coupon codes.
      //     }
      // });
    } catch (error) {
      console.log("createTicket ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    createTicket();
  }, []);

  if (Loading) {
    console.log("loading");
    return <Spinner />;
  }
  if (status == "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    // localStorage.setItem('hasReloaded', true);
    return (
      <div id="success" class="container py-5 fs-5">
        <p>
          {t("thankYou")}{" "}
          <p onClick={handleTicketPage} className="text-reset cursor d-inline text-decoration-underline">
            <b>{t("myticket")}</b>
          </p>
        </p>

        {/* <p>{t('thankYou')} <a onClick={handleTicketPage} className="text-reset cursor"> <b>{t('myticket')}</b></a></p> */}
        {/* <p>Tusen takk for ditt kjøp! Billetten(e) er nå sendt til din e-post og er også tilgjengelige under <a onClick={handleTicketPage} className="text-reset cursor"><b>'Mine billetter'</b></a> på din konto.<br/><br/> */}
        <p>{t("contactOrganizer")}</p>
        {/* Ved spørsmål angående showet du har kjøpt billett til, kontakt arrangøren direkte. For spørsmål om vårt system er du velkommen til å kontakte oss på sosiale medier, via e-post eller telefon. <br/><br/>

                Se <a href="/about" class="text-reset"><b>'Om oss'</b></a> for mer informasjon.

                </p> */}
        <p>
          {t("see")}{" "}
          <a href="/about" class="text-reset">
            <b>{t("aboutus")}</b>
          </a>
          {t("text2")}
        </p>
        {/* <a href="">t</a> */}
      </div>
    );
  }
};

export default Return;