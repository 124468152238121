import { useMyContext } from "MyContext";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { api } from "services";

const RequiredContext = ({ children }) => {
  const context = useMyContext();
  const [user, setUser] = useState("");
  const [role, setRole] = useState("");
  

  const fetchUserRole = async () => {
    try {
      const role = await api.getCurrentUserRoles();
      setRole(role);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  if (role === null) {
    return <Navigate to="/login" />;
  }

  if (role === "admin" || role === "organizer" || role === "user") {
    return <>{children}</>;
  }
};

export default RequiredContext;