// const Terms = () => {
//     return (
//         <div className="container my-5">
//             <div className="row content">
//                 <h1 className="text-center mb-2"><b>KJØPSVILKÅR FOR BILLETTKJØP</b></h1>
//                 <br /><br/><br/>
//                 <ul className="text-start justify-content-center fs-5">
//                     <li>Billetter kjøpt er bindende og kan ikke refunderes, byttes eller overføres til en annen person, med mindre Mintix AS gir tillatelse til dette.</li>
//                     <li>Mintix AS, org.nr. 929 782 402, er en billettformidler som tilbyr billetter til egne arrangementer. Ved å kjøpe billetter via Mintix AS aksepterer du disse kjøpsvilkårene.</li>
//                     <li>Billetter kan kun brukes til det spesifikke arrangementet på den angitte datoen og tidspunktet, med mindre Mintix AS gir tillatelse til å endre tidspunktet for arrangementet eller avlyse arrangementet helt. Ved avlysning vil du motta refusjon fra Mintix AS for billettprisen, men ikke eventuelle gebyrer eller tilleggsavgifter.</li>
//                     <li>Mintix AS har rett til å nekte adgang til arrangementet eller fjerne personer fra arrangementet som ikke overholder de fastsatte reglene eller oppfører seg på en måte som er til sjenanse for andre besøkende.</li>
//                     <li>Du må kunne fremvise gyldig billett ved ankomst til arrangementet.</li>
//                     <li>Billettprisen inkluderer tjenesteavgift og kan inkludere andre avgifter og gebyrer i tillegg til billettprisen. Betaling kan gjøres via Stripe eller Vipps.</li>
//                     <li>Billettene vil bli levert via e-post til den adressen som er oppgitt ved kjøp, så snart betalingen er gjennomført. Selv om du som kjøper har rett på refusjon, vil Mintix-provisjonen ikke refunderes. Hvis du ønsker å be om refusjon, vennligst kontakt Mintix AS.</li>
//                     <li>Mintix AS fraskriver seg ethvert ansvar for skade, tap eller død som skyldes opphold på arrangementet, med mindre skaden skyldes grov uaktsomhet fra Mintix AS side.</li>
//                     <li>Kjøpsbetingelsene kan endres når som helst, og eventuelle endringer vil bli publisert på Mintix nettside.</li>
//                     <li>Tvister skal løses ved en norsk domstol</li>
//                     <li>Angreretten gjelder ikke for kjøp av billetter til arrangementer i henhold til Angrerettloven kapittel 5 §19. Mintix AS har derfor ikke plikt til å refundere kjøpet ditt om du er forhindret fra å bruke billettene dine.</li>
//                     <li>Dersom du har klager eller spørsmål vedrørende arrangementet, vennligst kontakt oss direkte.</li>
//                     <li>All refusjon vil utbetales og administreres av Mintix AS.</li>
//                     <li>Merknad: Det er kjøperens ansvar å sette seg inn i og godta disse vilkårene før kjøpet gjennomføres.</li>
//                     <li>For eventuelle spørsmål eller problemer angående billettkjøp, kan du kontakte Mintix på e-post <a href="mailto:sondre@mintix.no" className="text-reset">sondre@mintix.no</a></li>
//                 </ul>
//                 <div className="text-start fs-5">Vi håper du vil ha en hyggelig opplevelse på arrangementet!</div>
//                 <br /><br />
                
//                 <address className="text-start fs-5">
//                 <strong>Vennlig hilsen,</strong><br />
//                 Mintix AS<br />
//                 Org. nr 929 782 402<br/>
//                 Johannes Gate 21, 4014 Stavanger<br/><br/>

//                 <b>Sist oppdatert 20. mars 2023</b>
//                 </address>
//             </div>
//         </div>
//     )
// }
// export default Terms;

import React from 'react';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation('terms');


  return (
    <div className="container my-5">
      <div className="row content">
        <h1 className="text-center mb-2"><b>{t('title')}</b></h1>
        <br /><br/><br/>
        <ul className="text-start justify-content-center fs-5">
          {t('termsAndConditions', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className="text-start fs-5">{t('closingMessage')}</div>
        <br /><br />
        
        <address className="text-start fs-5">
          <strong>{t('greeting')}</strong><br />
          Mintix AS<br />
          Org. nr 929 782 402<br/>
          {t('contactInfo.address')}<br/><br/>
          <b>{t('contactInfo.updateDate')}</b>
        </address>
      </div>
    </div>
  );
}

export default Terms;