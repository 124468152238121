import React, { useEffect, useState } from "react";
import { api } from "services";
import { useNavigate, useParams } from "react-router-dom";
import { validateForm } from "views/validation/Validations";
import { useTranslation } from "react-i18next";
import norway from "icons/norway.svg";
import imglogo from "../../images/imglogo.jpg";
import DatePicker from "react-datepicker";
import ConfirmDelete from "../popups/confirmDelete";
import "react-datepicker/dist/react-datepicker.css";

const AddEvent = ({ handleNext }) => {
  //add 2 hours ahead of current time
  let getdateTime = () => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 2);
    return currentDate;
  };
  const [eventDate, setEventDate] = useState(new Date());
  const [eventGateOpenTime, setevntGateOpenTime] = useState(new Date());
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [ticketSaleStartDate, setTicketSaleStartDate] = useState(new Date());
  // const [ticketSaleTime,setTicketSaleTime] = useState();

  const [event, setEvent] = useState({
    title: "",
    description: "",
    address: "",
    date: getdateTime(),
    sale_start_date: new Date(),
    sale_end_date: new Date(),
    gate_open_time: new Date(),
    gate_close_time: new Date(),
    error: "",
    max_tickets: 0,
    ticket_types: {
      Ordinær: "",
      "Student & U18": "",
      Ledsager: 0,
    },
    coverPhoto:
      "https://mintix.s3.eu-north-1.amazonaws.com/Capella+Party+2024+-+Banner.png",
  });

  const [errors, setErrors] = useState({});
  const [image, setImage] = useState("");
  const [action, setAction] = useState({});

  const { t } = useTranslation("addevent");
  const navigate = useNavigate();
  const params = useParams();
  const Id = params?.id;

  useEffect(() => {
    //Fetching Event based on Event Id
    const fetchEventById = async (id) => {
      const event = await api.getEventDetail(id);
      setEvent(event?.attributes);
      let newEvent = updateTicketPrcebyId(event?.attributes);
    };

    if (Id) {
      fetchEventById(Id);
    }
  }, []);

  //handle go back
  const goBack = () => {
    navigate(-1);
  };

  const removeSpace = (str) => {
    let newStr = str?.toString()?.replaceAll(" ", "");
    return newStr;
  };

  //handle reset Events
  const resetEvent = (e) => {
    e.preventDefault();
    setEvent({
      title: "",
      description: "",
      address: "",
      date: getdateTime(),
      error: "",
      ticket_types: {
        Ordinær: 0,
        "Student & U18": 0,
        Ledsager: 0,
      },
      max_tickets: 0,
      coverPhoto:
        "https://mintix.s3.eu-north-1.amazonaws.com/Capella+Party+2024+-+Banner.png",
    });
  };

  // convert number to norway currency eg 123,456,78
  const norwayCurrency = (num) => {
    let numStr = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return numStr;
  };

  //   const norwayCurrency = (amount) => {
  //     var formattedCurrency = new Intl.NumberFormat("nb-NO", {
  //         maximumFractionDigits: 0,
  //     }).format(amount);

  //     return formattedCurrency;
  // }

  //method to update the event date
  const handleUpdateEvent = async () => {
    const { err } = validateForm(event);
    if (!err) {
      try {
        if (Id) {
          const eventUpdated = await updateTicketPrices(event);
          const updatedEventResponse = await api.updateEvent(eventUpdated, Id);
          //this method will call the stepper next method
          handleNext(updatedEventResponse);
        } else {
          const newEvent = await updateTicketPrices(event);
          const res = await api.addEvent(newEvent);
          handleNext(res);
          setErrors({});
        }
      } catch (error) {
        console.log("handleUpdateEvent Error ", error);
      }
    } else {
      setErrors(err);
    }
  };

  //updating event ticketPrice to norway currency to show on UI when edit button click
  const updateTicketPrcebyId = (eventdata) => {
    const updated_ticket_price = {
      Ordinær: norwayCurrency(eventdata?.ticket_types?.Ordinær),
      "Student & U18": norwayCurrency(
        eventdata?.ticket_types?.["Student & U18"]
      ),
      Ledsager: norwayCurrency(eventdata?.ticket_types?.Ledsager),
    };

    let newEvent = {
      ...eventdata,
      ticket_types: updated_ticket_price,
    };
    setEvent(newEvent);
    return newEvent;
  };

  //removing norway currency while creating new Event or updating new event in database
  const updateTicketPrices = (eventdata) => {
    const updated_ticket_price = {
      Ordinær: removeSpace(eventdata?.ticket_types?.Ordinær),
      "Student & U18": removeSpace(eventdata?.ticket_types?.["Student & U18"]),
      Ledsager: removeSpace(eventdata?.ticket_types?.Ledsager),
    };
    let newEvent = {
      ...eventdata,
      ticket_types: updated_ticket_price,
    };
    // setEvent(newEvent)
    return newEvent;
  };

  //adding new Event
  const createEvent = async (e) => {
    e.preventDefault();
    try {
      if (Id) {
        //updading exsting event
        const eventUpdated = await updateTicketPrices(event);
        await api.updateEvent(eventUpdated, Id);
        navigate("/events");
      } else {
        //adding new event
        const newEvent = await updateTicketPrices(event);
        await api.addEvent(newEvent);
        navigate("/events");
      }
    } catch (err) {
      console.log("Creating or Updating Event Error ", err);
    }
  };

  //handle update all events
  const updateEvent = (e) => {
    const { name, value } = e.target;
    // console.log("name value ", name, value);
    if (
      ["Ordinær", "Student & U18", "Ledsager", "max_tickets"].includes(name)
    ) {
      let value2 = removeSpace(value);
      if (
        /^(?:(?:0|[1-9]\d{0,3}|[krKR]+)(?:,\d{0,2})?|10000(?:,0{1,2})?|\d{1,4})(?:,\d{1,2})?$|^$/.test(
          value2
        )
      ) {
        if (name === "max_tickets") {
          //max tickets
          setEvent({
            ...event,
            [name]: Number(value),
          });
        } else {
          //ordinary student and ledsagar
          let currency = norwayCurrency(value);
          setEvent((prev) => ({
            ...prev,
            ticket_types: {
              ...prev.ticket_types,
              [name]: currency,
            },
          }));
        }
      } else {
        setEvent((prev) => ({
          ...prev,
          ticket_types: {
            ...prev.ticket_types,
            [name]: event?.ticket_types?.[name],
          },
        }));
      }
    } else {
      setEvent({
        ...event,
        [name]: value,
      });
    }
    if (name === "date") {
      // console.log("name value is", name, value);
      setEventDate(value);
    }
    if (name === "gate_open_time") {
      setevntGateOpenTime(value);
    }
    if (name === "sale_start_date") {
      setTicketSaleStartDate(value);
    }
  };

  //Image show
  const handleImage = (e) => {
    const img = e.target.files[0];
    if (img) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(img);
    }
  };
  //delete Image
  const deleteImage = () => {
    setImage("");
  };

  //Custom Header for DatePicker calender
  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
  }) => {
    return (
      <div className="custom-header">
        <button type="button" className="arrow-button" onClick={decreaseYear}>
          {"<<"}
        </button>
        <button type="button" className="arrow-button" onClick={decreaseMonth}>
          {"<"}
        </button>
        <span className="header-text">
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </span>
        <button type="button" className="arrow-button" onClick={increaseMonth}>
          {">"}
        </button>
        <button type="button" className="arrow-button" onClick={increaseYear}>
          {">>"}
        </button>
      </div>
    );
  };

  //events form ticket types
  const ticketTypesHtml = () => {
    return (
      <div className="row">
        <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
          {t("ticketPrice")}
        </label>
        {/* <div className="col-12 d-md-flex justify-content-between"> */}
        <div className="col-md-4 col-12">
          <label className="form-check-label" for="checkbox1">
            {t("ordinary")}
          </label>
          <div className="input-group">
            <input
              type={Number}
              name="Ordinær"
              value={event?.ticket_types?.Ordinær}
              className="form-control py-2 rounded-end  bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.ordinaryCost")}
              onChange={updateEvent}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span
              className="input-group-text px-1 position-absolute end-0 py-2"
              id="basic-addon2"
            >
              <img className="rounded flag-logo" src={norway} alt="" />
              NOK
            </span>
          </div>
          <p className="text-danger m-0 p-0">
            {errors && errors?.ticket_types?.Ordinær}
          </p>
        </div>
        <div className="col-md-4 col-12 ">
          <label className="form-check-label " for="checkbox2">
            {t("student")}
          </label>
          <div className="input-group">
            <input
              type={Number}
              name="Student & U18"
              value={event?.ticket_types?.["Student & U18"]}
              className="form-control rounded-end py-2 bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.student")}
              onChange={updateEvent}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <span
              className="input-group-text position-absolute  py-2 end-0  px-1"
              id="basic-addon2"
            >
              <img className="rounded flag-logo" src={norway} alt="" />
              NOK
            </span>
          </div>
          <p className="text-danger mb-0">
            {errors && errors?.ticket_types?.["Student & U18"]}
          </p>
        </div>
        <div className="col-md-4 col-12 ">
          <label className="form-check-label" for="checkbox3">
            {t("companion")}
          </label>

          <div className="input-group">
            <input
              type={Number}
              name="Ledsager"
              value={event?.ticket_types?.Ledsager}
              className="form-control rounded-end py-2 bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.companion")}
              onChange={updateEvent}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              inputMode="numeric"
            />
            <span
              className="input-group-text px-1 position-absolute end-0 py-2"
              id="basic-addon2"
            >
              <img className="rounded flag-logo" src={norway} alt="" />
              NOK
            </span>
          </div>
          <p className="text-danger mb-0">
            {errors && errors?.ticket_types?.Ledsager}
            <p></p>
          </p>
        </div>
      </div>
      // </div>
    );
  };

  // Function to get the start of the next day at 00:00
  const getEndOfDay = (date) => {
    const endOfDay = new Date(date);
    endOfDay?.setHours(23, 59, 59, 999); // Set to the last moment of the day
    return endOfDay;
  };

  //events form max tickets and event shedule date and time
  const ticketCountAndScheduleRow = () => {
    return (
      <div className="row">
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("eventShedule")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            minDate={new Date()}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MM/d/yyyy HH:mm"
            isClearable
            name="date"
            selected={event?.date}
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "date" } })
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              decreaseYear,
              increaseYear,
            }) => (
              <CustomHeader
                date={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
          />
          <p className="text-danger">{errors && errors?.date}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("gateOpenTime")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            minTime={eventDate}
            maxTime={getEndOfDay(eventDate)} // Maximum selectable time set to 23:59:59
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat=" HH:mm"
            showDateSelect={false}
            name="gate_open_time"
            selected={event?.gate_open_time}
            placeholderText="Select Gate Open Time"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "gate_open_time" } })
            }
          />
          <p className="text-danger">{errors && errors?.gate_open_time}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("gateCloseTime")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            minTime={eventGateOpenTime}
            maxTime={getEndOfDay(new Date())}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat=" HH:mm"
            name="gate_close_time"
            selected={event?.gate_close_time || ""}
            placeholderText="Select Gate Close Time"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "gate_close_time" } })
            }
          />
          <p className="text-danger">{errors && errors?.gate_close_time}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("ticketSaleStart")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            minDate={new Date()}
            maxDate={new Date(event?.date)}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            // dateFormat="MM/d/yyyy h:mm aa"
            dateFormat="MM/d/yyyy HH:mm"
            isClearable
            name="sale_start_date"
            selected={event?.sale_start_date}
            placeholderText="Select Sale Start Date"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "sale_start_date" } })
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              decreaseYear,
              increaseYear,
            }) => (
              <CustomHeader
                date={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
          />
          <p className="text-danger">{errors && errors?.sale_start_date}</p>
        </div>
        <div className="col-md-4 col-12 ">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("maxTickets")}
          </label>
          <input
            type={Number}
            value={event?.max_tickets}
            onChange={updateEvent}
            className="form-control py-2 bg-transparent border-secondary-subtle"
            id="ticketCountInput"
            placeholder={t("placeholder.maxTickets")}
            name="max_tickets"
          />
          <p className="text-danger mb-0 pb-0">
            {errors && errors?.max_tickets}
          </p>
        </div>
      </div>
    );
  };

  //Admin form buttons
  const ButtonsRowHtml = () => {
    return (
      <>
        <div className="py-md-2 row mt-3">
          {/* reset */}
          <div className="col-4 d-grid">
            <button
              type="reset"
              onClick={resetEvent}
              className="btn btn-outline-secondary btn-block py-3 fw-bold"
            >
              {t("reset")}
            </button>
          </div>
          {/* save */}
          <div className="col-4 d-grid">
            {Id ? (
              <button
                className="btn btn-outline-secondary btn-block py-3 fw-bold"
                role="button"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#confirmDeleteModal"
                onClick={(e) => {
                  setAction({
                    actionName: createEvent,
                    actionBody: "Are you sure you want to Update",
                    actionTitle: "Update Action",
                  });
                }}
              >
                {/* {t("updateEvent")} */}
                {/* Save */}
                {t("save")}
              </button>
            ) : (
              <button
                type="button"
                onClick={createEvent}
                className="btn btn-outline-secondary btn-block py-3 fw-bold"
              >
                {t("save")}
              </button>
            )}
          </div>
          <div className="col-4 d-grid">
            {Id ? (
              <button
                type="button"
                className="btn btn-outline-secondary btn-block py-3 fw-bold"
                onClick={handleUpdateEvent}
              >
                {t("next")}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-secondary btn-block py-3 fw-bold"
                // onClick={goLive}
                onClick={handleUpdateEvent}
              >
                {t("next")}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-1">
            <i
              className="bi bi-arrow-left-circle h1"
              onClick={goBack}
              role="button"
            ></i>
          </div>  
          <div className="col-9">
            <h1 className="text-left">
              <b>{t("title")}</b>
            </h1>
          </div>
          <hr className="my-3" />
        </div>
        <div className="row my-3">
          {/* <div className="col-md-6 col-12 py-2">
            <div class="position-relative">
              <img
                src={image ? image : `${imglogo}`}
                className="img-fluid img-thumbnail w-100"
              />
              <div className="d-flex text-secondary px-4 py-1 fs-4 fw-bold position-absolute top-50 start-50  translate-middle">
                {
                  image ? <label htmlFor="fileInput" className="mx-1 btn btn-light">
                 <i class="bi bi-pencil"></i>
                </label>:<label htmlFor="fileInput" className="mx-1 btn btn-light">
                +
                </label>
                }
                
                {image && (
                  <label
                    className="mx-1 btn btn-light"
                    htmlFor="fileInputDelete"
                  >
                    <i class="bi bi-trash "></i>
                  </label>
                )}
              </div>
              <input
                type="file"
                id="fileInput"
                className="d-none"
                onChange={handleImage}
              />
              <input
                // type="file"
                id="fileInputDelete"
                className="d-none"
                onClick={deleteImage}
              />
            </div>
          </div> */}
          <div className="col-md-6 col-12 py-2">
            <input
              type="text"
              value={event?.title}
              required
              onChange={updateEvent}
              className="form-control py-2 bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.eventTitle")}
              name="title"
            />
            <p className="text-danger mb-0">{errors && errors?.title}</p>
            <br />
          </div>
          <div className="col-md-6 col-12 my-md-2">
            <input
              type="text"
              value={event?.address}
              onChange={updateEvent}
              className="form-control py-2 bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.eventAddress")}
              name="address"
            />
            <p className="text-danger mb-0">{errors && errors?.address}</p>
          </div>
          
          <div>

          <textarea
              rows={7}
              value={event?.description}
              onChange={updateEvent}
              className="form-control py-2 bg-transparent border-secondary-subtle"
              placeholder={t("placeholder.eventdescription")}
              name="description"
            />
            <p className="text-danger mb-0">{errors && errors?.description}</p>
          </div>
        </div>
        <div className="row">
         
          <div className="mb-5">
            {ticketTypesHtml()}
            {ticketCountAndScheduleRow()}
            {ButtonsRowHtml()}
          </div>
        </div>
        <ConfirmDelete handleAction={action?.actionName} action={action} />
      </div>
    </>
  );
};
export default AddEvent;