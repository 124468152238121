import { Navigate, useParams } from "react-router-dom";
import BuyBox from "components/BuyBox";
import { useEffect, useState, useContext, createContext } from "react";
import { api, auth } from "services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faCalendar, faClockFour } from "@fortawesome/free-regular-svg-icons";
import { currencyFormatter } from "utils";
import { useTranslation } from "react-i18next";
import NotFound from "./pages/NotFound";
import Spinner from "components/shared/Spinner";

const EventDetail = (props) => {
  const [event, setEvent] = useState(null);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [ticket, setTicket] = useState({ type1: 0, type2: 0, type3: 0 });
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState({
    code: "",
    amount: 0,
    status: "",
    type: -1,
  });

  const MAX_TICKETS = 30;
  const MAX_COMPANION_TICKET = 1;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("eventdetails");
  const [error, setError] = useState([]);

  const getTime = () => {
    if (event) {
      let indiaTime = event?.get("date");
      let options = { timeZone: "Europe/Oslo", hour12: false };
      let norwayTime = indiaTime.toLocaleString("en-US", options);

      let datetime = norwayTime.split(",");
      let timeparts = datetime[1].split(":");
      let hr = timeparts[0];
      let mn = timeparts[1];
      let starttime = `${hr}:${mn}`;
      let endtime = `${hr}:${parseInt(mn) + 30}`;
      return { starttime: starttime, endtime: endtime };
    }
  };

  //method to fetch the Event details based on event id
  const fetchEventDetails = async () => {
    try {
      await api.getEventDetail(id)?.then((event) => {
        setEvent(event);
      });
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, []);

  //coupon is removed then the coupon status set to default
  useEffect(() => {
    if (code === "") {
      setDiscount(0);
      setCoupon({
        code: "",
        amount: 0,
        status: "",
        type: -1,
      });
    }
  }, [code]);

  useEffect(() => {
    calculateDiscount();
  }, [ticket, coupon]);

  const updatePrice = (amount) => {
    setTotal(total + parseInt(amount));
  };

  const reduce = (price, i) => {
    if (i == 0) {
      if (ticket.type1 > 0) {
        setTicket({ ...ticket, type1: ticket.type1 - 1 });
        updatePrice(-price);
      }
    } else if (i == 1) {
      if (ticket.type2 > 0) {
        setTicket({ ...ticket, type2: ticket.type2 - 1 });
        updatePrice(-price);
      }
    } else if (i == 2) {
      if (ticket.type3 > 0) {
        setTicket({ ...ticket, type3: ticket.type3 - 1 });
        updatePrice(-price);
      }
    }
  };

  const add = (price, i) => {
    if (ticket.type1 + ticket.type2 + ticket.type3 < MAX_TICKETS) {
      if (i == 0) {
        if (ticket.type1 < MAX_TICKETS) {
          setTicket({ ...ticket, type1: ticket.type1 + 1 });
        }
      } else if (i == 1) {
        if (ticket.type2 < MAX_TICKETS) {
          setTicket({ ...ticket, type2: ticket.type2 + 1 });
        }
      } else if (i == 2) {
        if (ticket.type3 < MAX_COMPANION_TICKET) {
          setTicket({ ...ticket, type3: ticket.type3 + 1 });
        }
      }
      updatePrice(price);
    }
  };
  
  const buy = async () => {
    const user = auth.fetchUser();
    const response = await api?.getEventDetail(id);
    const arrayofTickets = Object.values(ticket);
    const available_tickets = response?.get("max_tickets");
    let selected_number_of_tickets = arrayofTickets.reduce((acc, cur) => acc + cur, 0);
    // console.log("selected_number_of_tickets available_tickets",selected_number_of_tickets,available_tickets)
    if (user) {
      if (selected_number_of_tickets <= available_tickets) {
        try {
          let amount = total - discount;
          let max_tickets_count = response.get("max_tickets");
          let sum = arrayofTickets.reduce((acc, cur) => acc + cur, 0);
          let total_tickets = max_tickets_count - sum;

          //creating new Booking
          const res = await api.createBooking(id, amount, coupon.code);
          let bookingId = res?.id;

          let booking_details = {
            eventId: id,
            totalTickets: total_tickets,
            ticketSoldOut: sum,
            ticketTypes: ticket,
            couponCode: coupon?.code,
            totalAmount: amount,
          };
          // storing the booking details for temporary
          let stringObj = JSON.stringify(booking_details);
          localStorage.setItem("booking", stringObj);

          if (user && bookingId) {
            const data = {
              email: user?.get("email"),
              amount: amount,
              bookingId: bookingId,
            };

            navigate("/checkout", {
              state: data,
            });
          }
        } catch (error) {
          console.log("error ", error);
        }
       setError("")
      } else {
        setError("Number of Tickets exceeds the available tickets");
      }
    } else {
      navigate("/login");
    }
  };

  const RenderTicketTypes = (props) => {
    const tickets = props.tickets;
    var types = Object.keys(tickets);
    return (
      <ul className="">
        {types.map((type, j) => {
          var keys = Object.keys(ticket);
          return (
            <li key={j}>
              <div className="row">
                <div className="col-md-6 col-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6">
                  <span className="fs-4">{type}</span>
                  <br />
                  <span className="fs-6">( {tickets[type]} NOK )</span>
                </div>
                <div
                  className="col-md-6 col-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6"
                  align="right"
                >
                  <button
                    class="btn btn-outline-light btn-sm"
                    onClick={() => reduce(tickets[type], j)}
                    disabled={ticket[keys[j]] == 0}
                  >
                    -
                  </button>
                  <span class="mx-4">{ticket[keys[j]]}</span>
                  <button
                    class="btn btn-outline-light btn-sm"
                    onClick={() => add(tickets[type], j)}
                    disabled={ticket[keys[j]] == MAX_TICKETS}
                  >
                    +
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const applyCoupon = () => {
    //Fetch coupon details from backend.
    if (ticket.type1 + ticket.type2 > 0) {
      api.getCouponByCode(code, id).then((result) => {
        if (result != null) {
          setCoupon({
            code: code,
            amount: result.get("amount"),
            status: "You saved ",
            type: result.get("type"),
          });
        } else {
          setCoupon({
            code: "",
            amount: 0,
            status: "Coupon Code Invalid",
            type: -1,
          });
          // setCode("");
        }
      });
    } else {
      //If no tickets selected then coupon code shouldn't be applied.
      setCoupon({
        code: "",
        amount: 0,
        status: "Please select Tickets first",
        type: -1,
      });
      setCode("");
    }
  };

  const calculateDiscount = () => {
    let _discount = 0;
    if (ticket.type1 + ticket.type2 > 0) {
      if (coupon.type == 0) {
        _discount = (ticket.type1 + ticket.type2) * coupon.amount;
      } else if (coupon.type == 1) {
        _discount = ticket.type1 * coupon.amount;
      } else if (coupon.type == 2) {
        _discount = ticket.type2 * coupon.amount;
      }
      setDiscount(_discount);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!event) return <NotFound />;
  return (
    <div className="container">
      <div class="row pb-5">
        <div class="col-md-10 col-12 offset-md-1 text-center" align="center">
          <img src={event?.get("cover_photo")} className="img-fluid" />
          <br />
          <br />
          <h1>{event?.get("title")}</h1>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-6">
          <FontAwesomeIcon icon={faCalendar} className="fs-5 mx-2" />
          <i>
            <b>{t("date")}</b>
            {event?.get("date")?.toDateString()}
          </i>
          <br />
          <FontAwesomeIcon icon={faClockFour} className="fs-5 mx-2" />
          <b>{t("time")}</b>
          {getTime()?.starttime}
          <br />
          {/* <FontAwesomeIcon icon={faCalendar} className="fs-5 mx-2" />
          <i>
          <b>Ticket Sale Start Date :</b>
         {event?.get("sale_start_date")?.toDateString()}
          </i>
          <br />
          <FontAwesomeIcon icon={faCalendar} className="fs-5 mx-2" />
          <i>
          <b>Ticket Sale End Date :</b>
        {event?.get("sale_end_date")?.toDateString()}
          </i>
          <br /> */}
          {/* <FontAwesomeIcon icon={faMicrophone} className="fs-5 mx-2"/><b>Konsertstart: </b>{getTime()?.endtime}<br /> */}
          <div className="d-flex">
            <div style={{ minWidth: "fit-content" }}>
              <FontAwesomeIcon icon={faLocationDot} className="fs-5 mx-2" />
              <b>{t("address")}</b>
            </div>
            <p> {event.get("address")}</p>
          </div>
          <br />

          <p></p>
          <span className="pre-wrap">{event?.get("description")}</span>
        </div>
        <br />
        <div class="col-md-6" align="left">
          <div class="bg-secondary p-3 p-md-4 text-white">
            <h3>
              <p>{t("maxTicket")}</p>
            </h3>
            <p>{t("description")}</p>
            <RenderTicketTypes tickets={event?.get("ticket_types")} />
            <h4 className="d-grid">
              {/* {t("subTotal")} : {currencyFormatter(total)} */}
              <br />
              <div class="input-group mt-3">
                <input
                  type="text"
                  value={code}
                  class="form-control bg-secondary text-white"
                  placeholder={t("code")}
                  aria-label="discount"
                  aria-describedby="button-discount"
                  disabled={ticket.type1 + ticket.type2 > 0 ? false : true}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setDiscount(0);
                    }
                    setCode(e.target.value);
                    setCoupon({ ...coupon, status: "" });
                  }}
                ></input>
                <button
                  class="btn btn-light"
                  type="button"
                  id="button-discount"
                  onClick={applyCoupon}
                  disabled={code == "" ? true : false}
                >
                  {t("couponCodebtn")}
                </button>
              </div>
              <div className="pb-3 pt-1 small text-end" align="left">
                <i>
                  <small>
                    {discount > 0
                      ? coupon.status + currencyFormatter(discount)
                      : coupon.status}
                  </small>
                </i>
              </div>
              <pre
                className="text-decoration-none m-0"
                style={{ fontFamily: "sans-serif" }}
              >
                {t("total")} : {currencyFormatter(total - discount)}
              </pre>

              <p className="fs-5 my-3 fst-italic">{error && error}</p>

              <button
                className="btn btn-outline-light btn-block btn-lg mt-3"
                onClick={buy}
                disabled={total > 0 ? false : true}
              >
                {t("buynow")}
              </button>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventDetail;
