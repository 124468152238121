import React, { useEffect, useState } from "react";
import { api, auth } from "services";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook} from "reactjs-social-login";
import {FacebookLoginButton} from "react-social-login-buttons";
import { useMyContext } from "MyContext";
import useStore from "services/store";


import axios from "axios";
import Parse from "parse";

const Login = () => {
  const [user,setUser] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [buttonWidth, setButtonWidth] = useState(500); // Default width in pixels
  const { t } = useTranslation(["login", "common"]);
  const context = useMyContext();
  const {handleUser,userData} = context;
  const navigate = useNavigate();
  const {fetchProfile} = useStore()

  useEffect(() => {
    const loadFacebookSDK = () => {
      return new Promise((resolve, reject) => {
        // Check if FB object is already available
        if (window.FB) {
          return resolve(window.FB);
        }

        // Load the Facebook SDK script
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          if (window.FB) {
            resolve(window.FB);
          } else {
            reject(new Error('Failed to load Facebook SDK'));
          }
        };
        script.onerror = () => reject(new Error('Failed to load Facebook SDK'));
        document.body.appendChild(script);
      });
    };

    const initializeFacebookSDK = async () => {
      try {
        const FB = await loadFacebookSDK();
        FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID, 
          cookie: true,
          xfbml: true,
          version: 'v13.0',
        });

        // Additional initialization code here
        // console.log('Facebook SDK initialized');
      } catch (error) {
        console.error('Error initializing Facebook SDK:', error);
      }
    };

    initializeFacebookSDK();
  }, []);
  
  useEffect(() => {
    const updateButtonWidth = () => {
      if (window.innerWidth < 430) {
        setButtonWidth(280);
      } else if (window.innerWidth < 768) {
        setButtonWidth(1000);
      } else {
        setButtonWidth(1000);
      }
    };

    //it will call the method when the windows is resized
    window.addEventListener("resize", updateButtonWidth);

    //dynamicaly set the width for google button
    updateButtonWidth();
    const user = auth.fetchUser();
    if(user){
     setUser(user)
    }
  }, []);

  //navigate home page dashaboard based on user role
  const handleNavigate = async () => {
    let role = await api.getCurrentUserRoles();
    if (role == "user") {
      navigate("/");
    } else if (role == "admin" || role == "organizer") {
      navigate("/dashboard");
    }
    // window.location.reload();
  };

  // User login method
  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const res = await auth.login(username, password);
      handleNavigate();
    } catch (err) {
      setError("Failed to log in. " + err.message);
    }
  };

  // Google Login or Sign up Method
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const credential = credentialResponse?.credential;
    try {
      const response = await axios.get(
        `https://oauth2.googleapis.com/tokeninfo?id_token=${credential}`
      );
      const profile = response.data;
      const { sub: id, email, name } = profile;

      const authData = {
        id,
        id_token: credential,
        expiration: Date.now() + 3600 * 1000, // 1 hour expiration
      };

      try {
        const existingUser = await api.getUserEmail(email);
        let user;
        if (existingUser) {
          try {
            // Check if the existing user has a different authentication method
            if (
              existingUser.get("authData") &&
              existingUser.get("authData").google
            ) {
              // Try to log in the user
              user = await Parse.User.logInWith("google", { authData });
              const userRole = await api.getCurrentUserRoles();
              //if user is present and  not assigned to any role by default it will assign to "user" Role
              if (user) {
                if (!userRole) {
                  const query = new Parse.Query(Parse.Role);
                  query.equalTo("name", "user");
                  const role = await query.first();
                  if (role) {
                    role.getUsers().add(user);
                    await role.save();
                    user.set("username", name);
                    await user.save();
                  } else {
                    console.error("Role 'user' not found.");
                  }
                }
              }
            } else if (
              existingUser.get("authData") &&
              existingUser.get("authData").facebook
            ) {
              // User exists with Facebook but is trying to sign up with Google
              console.error(
                "An account with this email already exists using a different provider."
              );
              alert("An account with this email already exists.");
              return;
            }
          } catch (error) {
            //session token is invalid re-link
            if (error.code === 209) {
              user = await Parse.User.current().linkWith("google", {
                authData,
              });
              await user.save();
            } else {
              throw error;
            }
          }
        }
      } catch (error) {
        if (error.code === 101) {
          // User does not exist, sign them up
          let user = new Parse.User();
          // user.set("username", id);
          user.set("username", name);
          user.set("password", id); // Use a secure password or unique identifier
          user.set("email", email);
          await user.linkWith("google", { authData });
          await user.save();

          //creating user Profile
          const Profile = Parse.Object.extend("Profile");
          const profile = new Profile();

          // Allow public access to the Profile object
          const profileACL = new Parse.ACL();
          profileACL.setPublicReadAccess(true);
          profileACL.setPublicWriteAccess(true);
          profile.setACL(profileACL);

          profile.set("firstname", name);
          profile.set("lastname", "");

          const newProfile = await profile.save();
          user.set("profileId", newProfile);

          // Assign the user to a default role is "user" if user is not assigned any role
          const query = new Parse.Query(Parse.Role);
          query.equalTo("name", "user");
          const role = await query.first();

          if (role) {
            role.getUsers().add(user);
            await role.save();
          } else {
            console.error("Role 'user' not found.");
          }
          user.set("email", email);
          await user.save();
        } else {
          if (error.code !== 101) {
            console.log("error is ", error);
            alert(error?.message);
          }
        }
      }
      handleNavigate();
    } catch (error) {
      console.error("Error: ", error, error.code);
      alert(error?.message);
    }
  };

  // Facebook login or Sign up method
  const handleFacebookSuccess = async (response) => {
    const { userID, accessToken } = response;
    try {
      // Fetch user details using the access token
      const userDetailsResponse = await axios.get(
        `https://graph.facebook.com/${userID}?fields=id,name,email&access_token=${accessToken}`
      );
      const { id, name, email } = userDetailsResponse.data;
      const authData = {
        id,
        access_token: accessToken,
        expiration: Date.now() + 3600 * 1000, // 1 hour expiration
      };

      let user;
      try {
        const existingUser = await api.getUserEmail(email);
        if (existingUser) {
          try {
            // Check if the existing user has a different authentication method
            if (
              existingUser.get("authData") &&
              existingUser.get("authData").facebook
            ) {
              // // Try to log in the user
              user = await Parse.User.logInWith("facebook", { authData });
              const userRole = await api.getCurrentUserRoles();
              // //if user is present and  not assigned to any role by default it will assign to "user" Role
              if (user) {
                if (!userRole) {
                  const query = new Parse.Query(Parse.Role);
                  query.equalTo("name", "user");
                  const role = await query.first();
                  if (role) {
                    role.getUsers().add(user);
                    await role.save();
                    user.set("username", name);
                    await user.save();
                  } else {
                    console.error("Role 'user' not found.");
                  }
                }
              }
            } else if (
              existingUser.get("authData") &&
              existingUser.get("authData").google
            ) {
              // User exists with Google but is trying to sign up with Facebook
              console.error(
                "An account with this email already exists using a different provider."
              );
              alert("An account with this email already exists.");
              return;
            }
          } catch (error) {
            //Session token invalid, attempting to re-link
            if (error.code === 209) {
              user = await Parse.User.current().linkWith("facebook", {
                authData,
              });
              await user.save();
            } else {
              throw error;
            }
          }
        }
      } catch (error) {
        if (error.code === 101) {
          // User does not exist, sign them up
          user = new Parse.User();
          // user.set("username", id);
          user.set("username", name);
          user.set("password", id); // Use a secure password or unique identifier
          user.set("email", email);
          await user.linkWith("facebook", { authData });
          await user.save();

          //creating user Profile
          const Profile = Parse.Object.extend("Profile");
          const profile = new Profile();

          // Allow public access to the Profile object
          const profileACL = new Parse.ACL();
          profileACL.setPublicReadAccess(true);
          profileACL.setPublicWriteAccess(true);
          profile.setACL(profileACL);

          profile.set("firstname", name);
          profile.set("lastname", "");

          const newProfile = await profile.save();
          user.set("profileId", newProfile);

          // Assign the user to a default role is "user" if user is not assigned any role
          const query = new Parse.Query(Parse.Role);
          query.equalTo("name", "user");
          const role = await query.first();

          if (role) {
            role.getUsers().add(user);
            await role.save();
            // console.log("User added to role: ", role);
          } else {
            console.error("Role 'user' not found.");
          }
          user.set("email", email);
          await user.save();
        } else {
          if (error.code !== 101) {
            alert(error?.message);
            console.log("error is ", error);
          }
        }
      }

      handleNavigate();
    } catch (error) {
      alert(error?.message);
      console.error("Error: ", error);
    }
    // const res = await auth.facebookLogin(response)
    // console.log("res ",res)
  };

  //google component
  const googleAuth = () => (
    <div className="d-flex justify-content-center">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div>
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={(error) => {
              alert("Login Failed");
              console.log("Login Failed", error);
            }}
            width={`${buttonWidth}px`}
            shape="pill"
          />
        </div>
      </GoogleOAuthProvider>
    </div>
  );

  //handle show hide eye icon
  const handleShowHidePassword = (eleId) => {
    const ele1 = document.getElementById(eleId);
    let node1 = ele1.childNodes[0];
    let node2 = ele1.childNodes[1];

    if (node1.type === "text") {
      node1.type = "password";
      node2.innerHTML = '<i class="bi bi-eye-slash"></i>';
    } else {
      node1.type = "text";
      node2.innerHTML = '<i class="bi bi-eye"></i>';
    }
  };

  if(user) return <Navigate to="/" replace={true} />
  return (
    <div className="container py-5 my-5">
      <div className="row py-3">
        <div className="col-md-4 offset-md-4">
          <h2 className="py-3 text-center">{t("title")}</h2>
          <form>
            <div id="myusername" className="position-relative">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control py-3 mb-2"
               placeholder={t('placeholder.username')}
              />
            </div>
            <div id="mypassword" className="position-relative">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control py-3 my-3"
               placeholder={t('placeholder.password')}
              />
              <span
                className="position-absolute top-0  end-0 p-3"
                id="eye2"
                style={{ cursor: "pointer" }}
                onClick={() => handleShowHidePassword("mypassword")}
              >
                <i class="bi bi-eye-slash"></i>
              </span>
              {error && <div className="error">{error}</div>}
            </div>
            <div className="d-grid">
              <button
                type="submit"
                onClick={handleLogin}
                className="btn btn-outline-secondary btn-block py-3 fw-bold"
              >
                {t("login", { ns: "common" })}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        {/* <div className="offset-md-4 col-md-4 text-center fs-5">
          <p className="p-0 m-0">--------------- {t('or')} ---------------</p>
        </div> */}
      </div>
      {/* <div className="row py-3">
        <div className="offset-md-4 col-md-4 text-center fs-5">
          {googleAuth()}
        </div>
      </div> */}
      {/* <div className="row py-3">
        <div className="offset-md-4 col-md-4 text-center fs-5">
          <LoginSocialFacebook
            isOnlyGetToken
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            onResolve={({ provider, data }) => {
              handleFacebookSuccess(data);
            }}
            onReject={(err) => {
              alert("Login Error");
              console.log("Facebook Login Error ", error);
            }}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook>
        </div>
      </div> */}
      <div className="row py-3 my-3">
        <div className="offset-md-4 col-md-4 text-center fs-5">
          <Link to="/signup" className="text-reset">
            {t("createAccount")}
          </Link>
          <Link to="/password-reset" className="px-3 text-reset ">
            {t("forgetpassword")}
          </Link>
        </div>
      </div>
      <div className="row py-3"></div>
    </div>
  );
};

export default Login